import { useCore } from "@/core-nextv3/core/core";
import { useRouter } from "next/router";
import { AlertPopup } from "./AlertPopup";
import { MessagePopup } from "./MessagePopup";
import styles from "./styles.module.scss";

type Props = {
	countMessages: number;
	countMessagesOthers: number;
	notifications: number;
}
export const NotificationPopup = ({
	countMessages,
	countMessagesOthers,
	notifications,
}:Props) => {
	const { setAwaitLoadingHTTP } = useCore();
	const router = useRouter();

	const handleClick = async (e: any) => {
		e.stopPropagation();
		setAwaitLoadingHTTP(true);
		router.push("/deposit/?tabIndex=4");
		setAwaitLoadingHTTP(false);
	};

	// unificar hooks de mensagem caso apareça mais modulos
	return (
		<div className={styles.notificationPopup}>
			{countMessages > 0 && (
				<MessagePopup counter={countMessages} tabIndex="3" />
			)}

			{countMessagesOthers > 0 && (
				<MessagePopup counter={countMessagesOthers} tabIndex="8" />
			)}

			{notifications > 0 && (
				<AlertPopup counter={notifications} handler={handleClick} />
			)}
		</div>
	);
};
