import { toZonedTime } from "date-fns-tz";
import { useTranslation } from "next-i18next";
import { useCookies } from "react-cookie";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMegaphone } from "react-icons/hi2";
import { ImageSetBet } from "../ImageSetBet";
import styles from "./styles.module.scss";

export const Popup: React.FC<any> = ({ popup, handleClosePopup }) => {
	const { t } = useTranslation();
	const [_cookie, setCookie] = useCookies([`${popup?.id}`]);

	const onClose = async () => {
		const expires = toZonedTime(new Date(), "Asia/Seoul");
		expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
		setCookie(`${popup?.id}`, "close", { path: "/", expires });
		await handleClosePopup(popup);
	};

	return (
		<div className={styles.popup}>
			<div className={styles.popupHeader}>
				<div className={styles.announce}>
					<HiOutlineMegaphone />
					<span>{t("Anuncio")}</span>
				</div>
				<div className={styles.notShow} onClick={() => onClose()}>
					<span>{t("Não exibir por 24h")}</span>
					<AiOutlineClose />
				</div>
			</div>
			{popup?.openOtherTab ? (
				<a
					href={popup?.link}
					target="_blank"
					className={styles?.popupBody}
					rel="noreferrer"
				>
					<ImageSetBet src={popup?.image?._url} alt="content" />
				</a>
			) : popup?.link ? (
				<a href={popup?.link} className={styles.popupBody}>
					<ImageSetBet src={popup?.image?._url} alt="content" />
				</a>
			) : (
				<div className={styles.popupBody}>
					<ImageSetBet src={popup?.image?._url} alt="content" />
				</div>
			)}
			<div className={styles.popupFooter}>
				<button onClick={() => handleClosePopup(popup)}>{t("Fechar")}</button>
			</div>
		</div>
	);
};
