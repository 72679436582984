import { useCore } from "@/core-nextv3/core/core";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AiFillStar } from "react-icons/ai";
import { RiQuestionAnswerFill } from "react-icons/ri";
import styles from "../styles.module.scss";

export const MessagePopup: React.FC<any> = ({ counter, tabIndex }: any) => {
	const { setAwaitLoadingHTTP } = useCore();
	const router = useRouter();
	const { t } = useTranslation();

	const handleClickMessages = async (e: any) => {
		e.stopPropagation();
		setAwaitLoadingHTTP(true);
		router.push(`/deposit/?tabIndex=${tabIndex}`);
		setAwaitLoadingHTTP(false);
	};

	return (
		<div
			className={styles.containerMessage}
			// style={{ bottom : notifications > 0 ? "300px" : "0" }}
			style={{ marginBottom: "10px" }}
		>
			<div className={styles.content} onClick={(e) => handleClickMessages(e)}>
				<div className={styles.popupHeader}>
					<div className={styles.titleArea}>
						<AiFillStar />
						<span className={styles.title}>{t("Verifique as mensagens")}</span>
						<AiFillStar />
					</div>
				</div>
				<div className={styles.popupBody}>
					<div className={styles.bodyContent}>
						<RiQuestionAnswerFill />
						<span>
							{t("Chegaram")} {counter} {t("Mensagem(s)")}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
