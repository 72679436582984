import type { TResponse } from "@/@types/response";
import { useCore } from "@/core-nextv3/core/core";
import {
	collectionDocument,
	countDocument,
	getDocument,
} from "@/core-nextv3/document/document.api";
import { phoneMaskv2 } from "@/core-nextv3/util/mask";
import {
	getAccid,
	getRecaptcha,
	sortArrayByProp,
} from "@/core-nextv3/util/util";
import { validateDate } from "@/core-nextv3/util/validate";
import {
	type TCreateSmsCall,
	type TValidateSmsCall,
	smsCall,
} from "@/hooks/smsCall";
import { useSubCore } from "@/hooks/useSubCore";
import {
	AUTH_SETTING,
	BANK_OPTIONS_SETTING,
	PARTNER_SETTING,
	THEME_SETTING,
	TRANSACTIONS_URL,
} from "@/setting/setting";
import axios from "axios";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
	AiOutlineClose,
	AiOutlineEye,
	AiOutlineEyeInvisible,
} from "react-icons/ai";
import InputMask from "react-input-mask";
import Select from "react-select";
import { AnimatedLoading } from "../AnimatedLoading";
import { ImageSetBet } from "../ImageSetBet";
import { AuthContext } from "../context/AuthContext";
import type { SiteConfig } from "../interfaces/IHeaderConfig";
import styles from "./styles.module.scss";
type TModalLogin = {
	closeModal: (value: boolean) => void;
	disableClose: boolean;
	setDisableClose: any;
	generalConfigs: any;
	logo?: any;
	siteConfig: SiteConfig;
};

export const ModalLogin = ({
	closeModal,
	disableClose,
	setDisableClose,
	generalConfigs,
	logo,
	siteConfig,
}: TModalLogin) => {
	const [passwordType, setPasswordType] = useState("password");
	const [passwordTypeTwo, setPasswordTypeTwo] = useState("password");
	const [phone, setPhone] = useState<any>();
	const [flagSameId, setFlagSameId] = useState(false);
	const [flagSameNickname, setFlagSameNickname] = useState(false);
	const [smsId, setSmsId] = useState<string>();
	const [phoneCode, setPhoneCode] = useState("");
	const [flagSmsCode, setFlagSmsCode] = useState(false);
	const [verifiedSms, setVerifiedSms] = useState(false);
	const [counter, setCounter] = useState(60);
	const [confirms, setConfirms] = useState({ code: false, nickname: false });
	const [checkedId, setCheckedId] = useState(false);
	const [checkedNickname, setCheckedNickname] = useState(false);
	const [bankOptions, setBankOptions] = useState<any>([]);
	const [activePartnerCode, setActivePartnerCode] = useState<any>(true);

	const { t } = useTranslation();

	const { selectedLanguage } = useCore();
	const { typeLogin, setTypeLogin, referral } = useSubCore();

	const { login } = useContext(AuthContext);

	const passwordRef: any = useRef();
	const confirmPasswordRef: any = useRef();

	const customSelectStyles: any = {
		control: (base: any, state: any) => ({
			...base,
			borderRadius: "4px",
			borderColor: state.isFocused ? "black" : "none",
			boxShadow: state.isFocused ? "none" : null,
			"&:hover": {
				borderColor: "none",
			},
			width: "100%",
			color: "#000",
		}),
		menu: (styles: any) => ({
			...styles,
			color: "#000",
			fontSize: "16px",
			zIndex: "9999",
		}),
		placeholder: (styles: any) => ({ ...styles, fontSize: "16px" }),
		singleValue: (styles: any) => ({
			...styles,
			color: "#000",
			fontSize: "16px",
		}),
		indicatorSeparator: () => null,
	};

	const {
		register,
		setValue,
		formState: { errors },
		handleSubmit,
		getValues,
		reset,
		setError,
		clearErrors,
	} = useForm();

	const { awaitLoadingHTTP, setAwaitLoadingHTTP } = useCore();
	const { setOpenMessageModal, setMessage, setMessageModalIcon } = useSubCore();

	const fetchOptions = async () => {
		if (typeLogin === "register" && bankOptions?.length === 0) {
			const result = await getDocument(BANK_OPTIONS_SETTING);

			if (result?.status) {
				const dataBanks = sortArrayByProp(result?.data?.items, "order");
				setBankOptions(dataBanks);
			}

			setVerifiedSms(false);
		}

		// if (!generalConfigs)
		// {
		//     const resultGeneral = await getDocument(GENERAL_CONFIGS_SETTING)

		//     if (resultGeneral?.status)
		//     {
		//         setGeneralConfigs(resultGeneral?.data)
		//     }
		// }
	};

	useEffect(() => {
		if (generalConfigs?.activePartnerCode) {
			setActivePartnerCode(true);
		} else {
			setActivePartnerCode(false);
		}
	}, [generalConfigs]);

	useEffect(() => {
		fetchOptions();
	}, [typeLogin]);

	useEffect(() => {
		if (typeLogin === "login") {
			reset({
				login: null,
				password: null,
				code: null,
			});
			setVerifiedSms(false);
		}

		// gambiarra, o valor do ref reseta na troca de formularios, isso resolve
		if (passwordRef?.current && getValues("password")) {
			passwordRef.current.value = getValues("password");
		}

		if (confirmPasswordRef?.current && getValues("confirmPassword")) {
			confirmPasswordRef.current.value = getValues("confirmPassword");
		}
		// setVerifiedSms(false);
	}, [typeLogin]);

	useEffect(() => {
		let id: NodeJS.Timeout | undefined = undefined;

		if (counter > 0) {
			id = setInterval(() => {
				setCounter((time) => time - 1);
			}, 1000);
		}

		return () => clearInterval(id);
	}, []);

	//console.warn('configs', generalConfigs)

	const handleLoginClick = async (data: any) => {
		data.login = data.login.toLowerCase().trim();
		// console.log("data login", data);
		// setAwaitLoadingHTTP(true);
		const loginResult: any = await login(data);

		if (
			!loginResult?.logged &&
			loginResult?.blocked &&
			!loginResult?.registerNotApproved &&
			!loginResult?.removed
		) {
			console.warn("BLOQUEADO");
			setMessageModalIcon("warning");
			setMessage(generalConfigs?.blockedUserMessage);
			setOpenMessageModal(true);
			setAwaitLoadingHTTP(false);
		} else if (
			!loginResult?.logged &&
			!loginResult?.blocked &&
			!loginResult?.registerNotApproved &&
			loginResult?.removed
		) {
			console.warn("RETIRADO");
			setMessageModalIcon("warning");
			setMessage(generalConfigs?.removedUserMessage);
			setOpenMessageModal(true);
			setAwaitLoadingHTTP(false);
		} else if (
			!loginResult?.logged &&
			loginResult?.registerNotApproved &&
			!loginResult?.blocked &&
			!loginResult?.removed
		) {
			console.warn("NAO APROVADO");
			setMessageModalIcon("warning");
			setMessage(
				t("Seu cadastro esta em analise, por-favor aguarde a aprovação"),
			);
			setOpenMessageModal(true);
			setAwaitLoadingHTTP(false);
		} else if (
			!loginResult?.logged &&
			!loginResult?.registerNotApproved &&
			!loginResult?.blocked &&
			!loginResult?.removed
		) {
			setMessageModalIcon("warning");
			setMessage(t(loginResult?.message).replace("{0}", loginResult?.count));
			setOpenMessageModal(true);
		} else {
			// setAwaitLoadingHTTP(true)
			// router.push('/')
			setAwaitLoadingHTTP(false);
		}
	};

	// 000-0000-0000
	const koreanPhone = async (
		phone: string,
		nameKey: string,
		setValue: (key: string, value: any) => void,
	) => {
		if (phone?.length > 13) {
			return setValue(nameKey, phone.slice(0, 13));
		}

		phone = phone
			.replace(/\D/g, "")
			.replace(/(\d{3})(\d)/, "$1-$2")
			.replace(/(\d{4})(\d)/, "$1-$2");

		setValue(nameKey, phone);
	};

	const handlePhoneChange = async (e: any) => {
		clearErrors("phone");
		let value: any;

		if (selectedLanguage === "pt") {
			value = await phoneMaskv2(e.target.value, "phone", setValue);
		} else {
			value = await koreanPhone(e.target.value, "phone", setValue);
		}

		// const value = await phoneMaskv2(e.target.value, "phone", setValue);
		// const value = await koreanPhone(e.target.value, "phone", setValue)
		setPhone(value);
	};

	const verifyID = async () => {
		const id = getValues("code");
		clearErrors("code");

		if (/[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g.test(id)) {
			setError("code", {
				type: "custom",
				message: `${t("O ID deve conter apenas letras minúsculas e números em inglês")}.`,
			});
			return false;
		}

		if (id) {
			setAwaitLoadingHTTP(true);
			const result = await collectionDocument(
				AUTH_SETTING?.merge({
					where: [
						{
							field: "code",
							operator: "==",
							value: id,
						},
					],
				}),
			);

			if (result?.count >= 0) {
				if (result?.count === 0) {
					//console.log("esta aqui no verifyId")
					setAwaitLoadingHTTP(false);
					setFlagSameId(false);
					setConfirms({ code: true, nickname: confirms.nickname });
					setCheckedId(true);
					return true;
				}

				setAwaitLoadingHTTP(false);
				setFlagSameId(true);
				return false;
			}

			setAwaitLoadingHTTP(false);
			setFlagSameId(true);
			return true;
		}

		setError("code", { type: "custom", message: t("Por-favor insira um ID") });
	};

	const verifyNickName = async () => {
		let nickname = getValues("nickname");
		clearErrors("nickname");

		if (nickname) {
			nickname = nickname.trim();
			setAwaitLoadingHTTP(true);
			const result = await collectionDocument(
				AUTH_SETTING?.merge({
					where: [
						{
							field: "nickname",
							operator: "==",
							value: nickname,
						},
					],
				}),
			);

			if (result?.status) {
				if (result?.count === 0) {
					setAwaitLoadingHTTP(false);
					setFlagSameNickname(false);
					setConfirms({ code: confirms.code, nickname: true });
					setCheckedNickname(true);
					return true;
				}

				setAwaitLoadingHTTP(false);
				setFlagSameNickname(true);
				setConfirms({ code: confirms.code, nickname: false });
				return false;
			}

			setAwaitLoadingHTTP(false);
			setFlagSameNickname(false);
			setConfirms({ code: confirms.code, nickname: true });
			return true;
		}

		setError("nickname", {
			type: "custom",
			message: t("Por-favor insira um nickname"),
		});
	};

	// console.warn('@@@@', checkedNickname)

	const handleSelect = async (e: any) => {
		setValue("bank", e);
	};

	const getPartner = async (userCode: string) => {
		if (userCode !== "") {
			const result = await collectionDocument(
				PARTNER_SETTING.merge({
					where: [
						{
							field: "userCode",
							operator: "==",
							value: userCode,
						},
					],
				}),
			);

			if (result?.status && result?.collection?.length === 1) {
				return result?.collection[0]?.referencePath;
			}

			return null;
		}
	};

	const handleRegister = async (data: any) => {
		// console.warn("checkedNickname", checkedNickname)
		setAwaitLoadingHTTP(true);

		if (!data.bank || Object.keys(data.bank).length === 0) {
			setMessageModalIcon("warning");
			setMessage("은행을 선택하십시요");
			setOpenMessageModal(true);

			return;
		}

		// console.log("data", data)

		const memoData = data;

		let birthday: any;

		if (data?.birthday) {
			try {
				birthday = new Date(data?.birthday)?.toISOString();
			} catch (e) {
				setAwaitLoadingHTTP(false);
				console.error(e);
				setError("birthday", {
					type: "custom",
					message: t("Data invalida"),
				});
				return;
			}
		}

		if (getAccid() === "default") {
			memoData.level = { id: "vlDFYo1k", label: "Level 1", value: 1 };
		} else {
			memoData.level = { id: "W0Psf9sY", label: "Level 10", value: 10 };
		}

		memoData.status = false;
		memoData.blocked = false;
		memoData.removed = false;
		memoData.simpleBet = true;
		memoData.groupBet = true;
		memoData.sportBet = true;
		memoData.slot = true;
		memoData.live = true;
		memoData.birthday = birthday || "";

		memoData.allowCasino = true;
		memoData.allowLiveSports = true;
		memoData.allowMinigame = true;
		memoData.allowSlot = true;
		memoData.allowSports = true;

		// console.log('CHECKPARTNER', checkPartner)

		if (!checkedId) {
			setAwaitLoadingHTTP(false);
			setError("code", {
				type: "custom",
				message: t("Por-favor verifique o ID"),
			});
			return;
		}

		if (!checkedNickname) {
			setAwaitLoadingHTTP(false);
			setError("nickname", {
				type: "custom",
				message: t("Por-favor verifique o nickname"),
			});
			return;
		}

		if (!verifiedSms) {
			setAwaitLoadingHTTP(false);
			setError("phone", {
				type: "custom",
				message: t("Por-favor verifique seu telefone"),
			});
			return;
		}

		if (generalConfigs?.hasGlobalPartnerCode && !memoData?.partnerCode) {
			// memoData.partner = {
			//     referencePath : await getPartner(generalConfigs?.globalPartnerCode),
			// }

			memoData.partnerCode = generalConfigs?.globalPartnerCode;
		} else if (
			generalConfigs?.activePatnerCode ||
			(!generalConfigs?.activePatnerCode &&
				!generalConfigs?.globalPartnerCode &&
				memoData?.partnerCode)
		) {
			const checkPartner = await getPartner(memoData?.partnerCode);

			if (!checkPartner) {
				setAwaitLoadingHTTP(false);
				setError("partnerCode", {
					type: "custom",
					message: t("Por-favor insira um codigo de parceiro valido"),
				});
				return;
			}

			if (checkPartner) {
				// memoData.partner     = { referencePath : checkPartner }
				// memoData.partnerCode = memoData.partnerCode
			}
		}

		// biome-ignore lint/performance/noDelete: <explanation>
		delete memoData?.login;

		const token = await getRecaptcha("addUser");

		try {
			// const result = await addUserAuth(
			//     AUTH_SETTING.merge({
			//         data  : memoData,
			//         token : token
			//     })
			// )

			await axios.post(
				`${TRANSACTIONS_URL}/user/create-user`,
				AUTH_SETTING.merge({
					data: memoData,
					recaptchaToken: token,
				}),
			);

			setMessageModalIcon("success");
			setMessage(generalConfigs?.registerMessage);
			setOpenMessageModal(true);
			setConfirms({ code: false, nickname: false });
			// setModalType('login')
			setTypeLogin("login");

			if (!logo) {
				closeModal(false);
			}
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				error?.data?.message ||
				JSON.stringify(error);

			// if (error?.response?.data?.errorCode !== undefined)
			// {
			//     message = message + ` (Error: ${error?.response?.data?.errorCode})`
			// }

			toast.error(`${t(message)}`);
		} finally {
			setAwaitLoadingHTTP(false);
		}
	};

	const handleOnlyKorean = (value: any, key: any) => {
		const check = value.match(
			/[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g,
		);

		if (key === "nickname") {
			setConfirms({ code: confirms.code, nickname: false });
			setFlagSameNickname(false);
		}

		if (check !== null && check?.length === value?.length) {
			setValue(key, value);
		} else {
			setValue(key, "");
		}
	};

	const removePhoneMask = (value: any) => {
		let aux = "";

		for (let i = 0; i < value?.length; i++) {
			if (!Number.isNaN(Number(value[i]))) {
				aux += value[i];
			}
		}

		if (selectedLanguage === "pt") {
			const memo = aux?.split(" ");
			return `${memo[0]}${memo[1]}`;
		}

		return aux;
	};

	const checkUserPhone = async () => {
		// const phone = getValues("phone")
		const phone = removePhoneMask(getValues("phone"));

		const result = await countDocument(
			AUTH_SETTING.merge({
				where: [
					{
						field: "phone",
						operator: "==",
						value: phone,
					},
				],
			}),
		);

		if (result?.count > 0) {
			return false;
		}

		const phone2 = getValues("phone");

		const result2 = await countDocument(
			AUTH_SETTING.merge({
				where: [
					{
						field: "phone",
						operator: "==",
						value: phone2,
					},
				],
			}),
		);

		if (result2?.count > 0) {
			return false;
		}

		return true;
	};

	const verifyPhone = async () => {
		if (!awaitLoadingHTTP) {
			setAwaitLoadingHTTP(true);
			const value = removePhoneMask(getValues("phone"));
			clearErrors("phone");
			const accid: string | undefined = getAccid();

			let recipient: any;
			// biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
			selectedLanguage === "pt" ? (recipient = "55") : (recipient = "82");

			if (getValues("phone") && (await checkUserPhone())) {
				const data = {
					channel: "SMS",
					recipient: `${recipient}${value}`,
					accid: accid,
					maccid: "default",
				};

				const result: TResponse<TCreateSmsCall> = await smsCall(
					"mfa/tokens",
					data,
				);
				setAwaitLoadingHTTP(false);

				if (result.status) {
					setSmsId(result?.data?.id);
					setModalType("smsVerification");
					setCounter(60);
				} else {
					setMessageModalIcon("warning");
					const message = t(result?.error);
					setMessage(message);
					setOpenMessageModal(true);
				}
			} else if (!getValues("phone")) {
				setError("phone", {
					type: "custom",
					message: t("Por-favor insira seu n° de celular"),
				});
			} else if (!(await checkUserPhone())) {
				setAwaitLoadingHTTP(false);
				const message = t("Numero de telefone jah esta cadastrado");
				setMessage(message);
				setOpenMessageModal(true);
			}

			setAwaitLoadingHTTP(false);
		}
	};

	const handleResendToken = async () => {
		setAwaitLoadingHTTP(true);
		const phone = removePhoneMask(getValues("phone"));

		let recipient: any;
		// biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
		selectedLanguage === "pt" ? (recipient = "55") : (recipient = "82");

		if (phone) {
			const accid: string | undefined = getAccid();
			const data = {
				channel: "SMS",
				recipient: `${recipient}${phone}`,
				accid: accid,
			};

			const result: TResponse<TCreateSmsCall> = await smsCall(
				"mfa/tokens",
				data,
			);

			if (result) {
				setSmsId(result?.data?.id);
			} else {
				setMessageModalIcon("warning");
				// @ts-ignore
				const message = t(result?.error);
				setMessage(message);
				setOpenMessageModal(true);
			}
		}

		setCounter(60);
		setAwaitLoadingHTTP(false);
	};

	const handleCodeVerificationInput = (e: any) => {
		const result = e.target.value.replace(/\D/g, "");

		if (result?.length <= 6) {
			setPhoneCode(result);
		}
	};

	const handleVerifyPhone = async () => {
		setAwaitLoadingHTTP(true);

		if (phoneCode?.length === 6) {
			setFlagSmsCode(false);
			const accid = getAccid();
			const data = {
				id: smsId,
				token: phoneCode,
				accid: accid,
				maccid: "default",
			};

			const result: TResponse<TValidateSmsCall> = await smsCall(
				"mfa/tokens/validate",
				data,
			);

			if (result?.data?.verified) {
				setVerifiedSms(true);
				setModalType("register");
				// setTypeLogin('register')
				// setDisableClose(false)
			} else if (result && !result?.data?.verified) {
				setMessageModalIcon("warning");
				const message = t(
					"Codigo incorreto ou invalido, por-favor tente novamente",
				);
				setMessage(message);
				setOpenMessageModal(true);
			} else {
				setMessageModalIcon("warning");
				const message = t(result?.error);
				setMessage(message);
				setOpenMessageModal(true);
			}
		} else {
			setFlagSmsCode(true);
		}

		setAwaitLoadingHTTP(false);
	};

	const getTitle = () => {
		if (typeLogin === "login") return <h1>{t("Login")}</h1>;
		if (typeLogin === "register") return <h1>{t("Cadastro")}</h1>;
		if (typeLogin === "smsVerification") return <h1>{t("Verificar SMS")}</h1>;
	};

	const setModalType = (modal: any) => {
		if (!logo) {
			setDisableClose(false);
		}

		setTypeLogin(modal);
	};

	return (
		<div className={styles.modalLogin}>
			<div className={styles.container} onClick={(e) => e.stopPropagation()}>
				<div className={styles.content}>
					{!disableClose && !logo && (
						<AiOutlineClose
							className={styles.closeModalSvg}
							onClick={() => closeModal(false)}
						/>
					)}

					<div className={styles.image}>
						{logo ? <ImageSetBet alt="logo" src={logo} /> : getTitle()}
					</div>

					{typeLogin === "login" && (
						<form autoComplete="on">
							<div className={styles.inputControl}>
								<label htmlFor="login">{t("ID")}</label>
								<input
									id="login"
									// autocomplete="one-time-code"
									// id="loginInput"
									// style={{ textTransform: "lowercase" }}
									type="code"
									placeholder={t("ID")}
									{...register("login", {
										required: t("Este campo é obrigatório!"),
									})}
									// autoComplete="new-password"
									autoComplete="username"
									className="bg-white"
								/>
								{errors?.login && (
									// <ErrorMessage message={t(errors?.login?.message as string)} />
									<span className={styles.ErrorMessage}>
										{t(errors?.login?.message as string)}
									</span>
								)}
							</div>

							<div className={styles.inputControl}>
								<label htmlFor="password">{t("Senha")}</label>
								<div className={styles.inputPassword}>
									<input
										// autoComplete="off"
										id="password"
										{...register("password", {
											required: t("Este campo é obrigatório!"),
											minLength: {
												value: 8,
												message: t("Minimo de Caracteres: ") + 8,
											},
										})}
										type={passwordType}
										placeholder={t("Senha")}
										autoComplete="current-password"
										className="bg-white"
									/>
									{passwordType === "password" ? (
										<AiOutlineEye
											className={styles.iconPasswordPrimary}
											onClick={() => setPasswordType("text")}
										/>
									) : (
										<AiOutlineEyeInvisible
											className={styles.iconPasswordPrimary}
											onClick={() => setPasswordType("password")}
										/>
									)}
								</div>
								{errors.password && (
									// <ErrorMessage message={t("Confirme sua senha")} />
									<span className={styles.errorMessage}>
										{t("Confirme sua senha")}
									</span>
								)}
							</div>

							<div className={styles.btns}>
								<button onClick={handleSubmit(handleLoginClick)}>
									{awaitLoadingHTTP ? (
										<AnimatedLoading />
									) : (
										<span>{t("Entrar")}</span>
									)}
								</button>
								<button onClick={() => setModalType("register")}>
									<span>{t("Criar Conta")}</span>
								</button>
							</div>
						</form>
					)}

					{typeLogin === "register" && (
						<>
							{/* <h1>Cadastro</h1> */}

							<form>
								<div className={styles.inputItem}>
									<div className={styles.inputControl}>
										<label htmlFor="_loginInput">{t("ID")}</label>
										<div className={styles.input}>
											<input
												id="_loginInput"
												// style={{ textTransform: "lowercase" }}
												type="code"
												placeholder={t("4 a 12 caracteres")}
												onInput={(e) => {
													e.preventDefault();
												}}
												{...register("code", {
													required: t("Este campo é obrigatório!"),
													validate: (value: any) => {
														if (value?.length < 4) {
															return t(
																"Seu ID deve ter entre 4 e 12 caracteres",
															);
														}
														if (value?.length > 12) {
															return t(
																"Seu ID deve ter entre 4 e 12 caracteres",
															);
														}
													},
													onChange: (e: any) => {
														e.target.value = e?.target?.value.toLowerCase();
														e.target.value = e?.target?.value.replace(
															/[^a-zA-Z0-9\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g,
															"",
														);

														setFlagSameId(false);
														setCheckedId(false);
														setValue("code", e?.target?.value);
														setConfirms({
															code: false,
															nickname: confirms.nickname,
														});
													},
												})}
												autoComplete="new-password"
											/>

											<button
												onClick={(e) => {
													e.preventDefault();
													verifyID();
												}}
											>
												<span>{t("Validar Duplicidade")}</span>
											</button>
										</div>
										{errors?.code ? (
											// <ErrorMessage message={errors?.code?.message} />
											<span className={styles.errorMessage}>
												{t(String(errors?.code?.message))}
											</span>
										) : flagSameId ? (
											// <ErrorMessage message={t("Esse ID não pode ser usado")} />
											<span className={styles.errorMessage}>
												{t("Esse ID não pode ser usado")}
											</span>
										) : (
											""
										)}
										{confirms?.code && (
											// <ConfirmMessage message={t("Pode ser usado esse ID")} />
											<span className={styles.confirmMessage}>
												{t("Pode ser usado esse ID")}
											</span>
										)}
									</div>
								</div>

								<div className={styles.inputControl}>
									<div className={styles.perfilPasswords}>
										<div className={styles.inputPassword}>
											<label>{t("Senha")}</label>
											<input
												id="passwordInput"
												{...register("password", {
													required: t("Este campo é obrigatório!"),
													minLength: {
														value: 8,
														message: `${t("Minimo de Caracteres: ")} ${8}`,
													},
													validate: (value: any) => {
														if (
															/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
																value,
															)
														) {
															return t(
																"A senha não pode ter caracteres especiais",
															);
														}
													},
												})}
												type={passwordType}
												ref={passwordRef}
												placeholder={t("Não usar #&+")}
												onChange={(e) => {
													clearErrors("password");
													setValue("password", e?.target?.value);
													passwordRef.current.value = e?.target?.value;
												}}
												autoComplete="new-password"
												// onChange={(e) => formatPassword('password', e?.target?.value, passwordRef)}
											/>
											{errors.password && (
												// <ErrorMessage message={errors.password?.message} />
												<span className={styles.errorMessage}>
													{t(errors.password?.message as string)}
												</span>
											)}
											{passwordType === "password" ? (
												<AiOutlineEye
													className={styles.iconPassword}
													onClick={() => setPasswordType("text")}
												/>
											) : (
												<AiOutlineEyeInvisible
													className={styles.iconPassword}
													onClick={() => setPasswordType("password")}
												/>
											)}
										</div>

										<div className={styles.inputPassword}>
											<label>{t("Confirme sua senha")}</label>
											<input
												id="passwordInput"
												{...register("confirmPassword", {
													required: t("Este campo é obrigatório!"),

													validate: () =>
														confirmPasswordRef.current.value ===
															passwordRef.current.value ||
														t(
															"A confirmação de senha precisa ser igual a senha",
														),
												})}
												type={passwordTypeTwo}
												ref={confirmPasswordRef}
												placeholder={t("Confirme sua senha")}
												onChange={(e) => {
													clearErrors("confirmPassword");
													setValue("confirmPassword", e?.target?.value);
													confirmPasswordRef.current.value = e?.target?.value;
												}}
												autoComplete="new-password"
												// onChange={(e) => formatPassword('confirmPassword', e?.target?.value, confirmPasswordRef)}
											/>
											{passwordTypeTwo === "password" ? (
												<AiOutlineEye
													className={styles.iconPassword}
													onClick={() => setPasswordTypeTwo("text")}
												/>
											) : (
												<AiOutlineEyeInvisible
													className={styles.iconPassword}
													onClick={() => setPasswordTypeTwo("password")}
												/>
											)}
											{errors.confirmPassword && (
												// <ErrorMessage
												//     message={t("Coloque a mesma senha para confirmar.")}
												// />
												<span className={styles.errorMessage}>
													{t("Coloque a mesma senha para confirmar.")}
												</span>
											)}
										</div>
									</div>
								</div>

								<div className={styles.inputItem}>
									<div className={styles.inputControl}>
										<label>{t("Nickname")}</label>
										<div className={styles.input}>
											<input
												type="nickname"
												placeholder={t("2 a 6 caracteres")}
												{...register("nickname", {
													required: t("Este campo é obrigatório!"),
													validate: (value: any) => {
														if (value?.length < 2) {
															return t(
																"Os apelidos só podem ser em coreano (2 a 6 caracteres).00000 é um apelido que pode ser usado",
															);
														}
														if (value?.length > 6) {
															return t(
																"Os apelidos só podem ser em coreano (2 a 6 caracteres).00000 é um apelido que pode ser usado",
															);
														}
													},
													onChange: (e: any) => {
														setCheckedNickname(false);
														handleOnlyKorean(e?.target?.value, "nickname");
													},
												})}
											/>

											<button
												onClick={(e: any) => {
													e.preventDefault();
													verifyNickName();
												}}
											>
												<span>{t("Validar Duplicidade")}</span>
											</button>
										</div>
										{errors.nickname ? (
											// <ErrorMessage
											//     message={t(String(errors?.nickname?.message))}
											// />
											<span className={styles.errorMessage}>
												{t(String(errors?.nickname?.message))}
											</span>
										) : flagSameNickname ? (
											// <ErrorMessage message={t("Nickname em uso!")} />
											<span className={styles.errorMessage}>
												{t("Nickname em uso!")}
											</span>
										) : (
											""
										)}
										{confirms?.nickname && (
											// <ConfirmMessage
											//     message={t("Este é um apelido que você pode usar.")}
											// />
											<span className={styles.confirmMessage}>
												{t("Este é um apelido que você pode usar.")}
											</span>
										)}
									</div>
								</div>

								{/* por enquanto so estara disponivel no tema cassino (temporario talvez) */}
								{THEME_SETTING.themeCasino && (
									<div className={styles.inputRow}>
										<div className={styles.inputControl}>
											<label>{t("Data de Nascimento")}</label>
											<InputMask
												mask="9999-99-99"
												type="tel"
												autoComplete="new-password"
												placeholder="9999-99-99"
												className={styles.input}
												{...register("birthday", {
													required: t("Este campo é obrigatório!"),
													validate: (value) =>
														validateDate(value, "yyyy-MM-dd") ||
														t("Data invalida"),
												})}
												onChange={(e) => {
													clearErrors("birthday");
													setValue("birthday", e.target.value);
												}}
											/>
											{errors.birthday && (
												// <ErrorMessage message={t(errors.birthday.message as string)} />
												<span className={styles.errorMessage}>
													{t(errors.birthday.message as string)}
												</span>
											)}
										</div>

										{/* {THEME_SETTING.hasEmail && ( */}
										{siteConfig?.registerUser?.requiredFields?.email && (
											<div className={styles.inputControl}>
												<label htmlFor="email">{t("Email")}</label>
												<input
													id="email"
													type="email"
													placeholder={t("Email")}
													{...register("email", {
														required: t("Este campo é obrigatório!"),
														pattern: {
															value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
															message: t("Email inválido"),
														},
													})}
												/>
												{/* {errors.email && <ErrorMessage message={String(errors.email.message)} />} */}
												{errors.email && (
													<span className={styles.errorMessage}>
														{String(errors.email.message)}
													</span>
												)}
											</div>
										)}
									</div>
								)}

								<div className={styles.inputItem}>
									<div className={styles.inputControl}>
										<label>{t("Celular")}</label>
										<div className={styles.input}>
											<input
												type="tel"
												placeholder={t("Telefone")}
												{...register("phone", {
													required: t("Este campo é obrigatório!"),
													validate: (value: any) =>
														value.length <= 15 || t("Celular invlido!"),
												})}
												onChange={(e: any) => {
													handlePhoneChange(e);
												}}
												defaultValue={phone}
												disabled={verifiedSms}
											/>

											{verifiedSms === false && (
												<button
													onClick={(e) => {
														e?.preventDefault();
														verifyPhone();
													}}
												>
													<span>{t("Verificar SMS")}</span>
												</button>
											)}
										</div>
										{errors.phone && (
											// <ErrorMessage message={String(errors?.phone?.message)} />
											<span className={styles.errorMessage}>
												{String(errors?.phone?.message)}
											</span>
										)}
									</div>
								</div>

								<div className={styles.inputControl}>
									<div className={styles.documents}>
										<div className={styles.document}>
											<label>{t("Nome do Banco")}</label>
											<Select
												placeholder={t("Escolha seu banco")}
												className={styles.select}
												{...register("bank", {
													required: t("Este campo é obrigatório!"),
												})}
												options={bankOptions}
												styles={customSelectStyles}
												isClearable
												isSearchable={false}
												onChange={(e: any) => handleSelect(e)}
												// value={gender}
												// onChange={handleChangeGender}
											/>
											{errors.bank && (
												// <ErrorMessage message={t("Escolha seu banco")} />
												<span className={styles.errorMessage}>
													{t("Escolha seu banco")}
												</span>
											)}
										</div>

										<div className={styles.document}>
											<label>{t("N° da conta sem (-)")}</label>
											<input
												className={styles.inpot}
												type="number"
												placeholder={t("N° da conta")}
												{...register("accountNumber", {
													required: t("Este campo é obrigatório!"),
												})}
											/>
											{errors.accountNumber && (
												// <ErrorMessage
												//     message={t("Coloque seu numero da conta.")}
												// />
												<span className={styles.errorMessage}>
													{t("Coloque seu numero da conta.")}
												</span>
											)}
										</div>
									</div>
								</div>

								<div className={styles.inputControl}>
									<label>{t("Nome(somente coreano)")}</label>
									<input
										type="text"
										placeholder={t("Nome(somente coreano)")}
										{...register("name", {
											required: t("Este campo é obrigatório!"),
											onChange: (e: any) => {
												handleOnlyKorean(e?.target?.value, "name");
											},
										})}
									/>
									{errors.name && (
										// <ErrorMessage message={t(errors.name.message as string)} />
										<span className={styles.errorMessage}>
											{t(errors.name.message as string)}
										</span>
									)}
								</div>

								<div className={styles.inputControl}>
									<label>{t("Código de cadastro")}</label>
									<input
										type="text"
										placeholder={t("Código de cadastro")}
										defaultValue={referral ? referral : ""}
										readOnly={!!referral}
										{...register("partnerCode", {
											required:
												activePartnerCode && t("Este campo é obrigatório!"),
											onChange: (e: any) => {
												e.target.value = e?.target?.value.toLowerCase();
											},
											// {
											//     //e.target.value = e?.target?.value.toLowerCase()
											//     e.target.value = e?.target?.value.replace(/[^a-z0-9]/g, "").toLowerCase();
											// },
											// onChange : (e: any) =>
											// {
											//     //e.target.value = e?.target?.value.toLowerCase()
											//     e.target.value = e?.target?.value.replace(/[^a-z0-9]/g, "").toLowerCase();
											// },
										})}
									/>
									{errors.partnerCode && (
										// <ErrorMessage message={t(errors.partnerCode.message as string)} />
										<span className={styles.errorMessage}>
											{t(errors.partnerCode.message as string)}
										</span>
									)}
								</div>

								<div className={styles.btns}>
									{awaitLoadingHTTP ? (
										<div>
											<AnimatedLoading />
										</div>
									) : (
										<button onClick={handleSubmit(handleRegister)}>
											<span>{t("Cadastrar")}</span>
										</button>
									)}
									<button onClick={() => setTypeLogin("login")}>
										<span>{t("Voltar")}</span>
									</button>
								</div>
							</form>
						</>
					)}

					{typeLogin === "smsVerification" && (
						<div className={styles.verificationSms}>
							<div className={styles.contentCode}>
								<div className={styles.label}>
									<p>{t("insira o código recebido no sms")}</p>
								</div>
								<div className={styles.input}>
									<input
										type="tel"
										placeholder={`${t(
											"Código de 6 dígitos ex:123456",
										)}  ${getValues("phone")} ${t("enviado p/ N°")}`}
										value={phoneCode}
										onChange={(e: any) => handleCodeVerificationInput(e)}
										className="bg-white"
									/>
									{flagSmsCode && (
										// <ErrorMessage message={t("O código deve ter 6 digitos")} />
										<span className={styles.errorMessage}>
											{t("O código deve ter 6 digitos")}
										</span>
									)}
								</div>
								<div className={styles.resendArea}>
									{counter > 0 ? (
										<div className={styles.countDown}>
											<p>
												{t("Aguarde")} {counter}{" "}
												{t("segundos p/ solicitar novo codigo")}
											</p>
										</div>
									) : (
										<div className={styles.btn}>
											<button
												className={styles.btnActive}
												onClick={() => handleResendToken()}
											>
												{t("Reenviar SMS")}
											</button>
										</div>
									)}
								</div>
								<div className={styles.btn}>
									<button
										className={styles.btnActive}
										onClick={() => handleVerifyPhone()}
									>
										{t("Verificar")}
									</button>
								</div>
								<div className={styles.btn}>
									<button
										className={styles.btnActive}
										onClick={() => setModalType("register")}
									>
										{t("Voltar")}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
