export type TCreateSmsCall = {
	channel: string;
	createdAt: string;
	id: string;
};
export type TValidateSmsCall = {
	id: string;
	token: string;
	verified: boolean;
};
export const smsCall = async (endpoint: string, data: any) => {
	const baseUrl = process.env.NEXT_PUBLIC_API_SOURCE_URL;
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": "true",
	};

	const result = await fetch(`${baseUrl}/${endpoint}`, {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	});

	if (result?.status === 200) {
		const response = await result.json();
		return response;
	}

	console.error(result);
	return null;
};
